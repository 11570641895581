import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';
import SearchFilters from '@components/search-filters/search-filters';
import OpinionsSection from '@components/opinions-section/opinions-section';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import AboutSection from '@components/about-section/about-section';
import PromoSection from '@components/promo-section/promo-section';
import BlogPostsSection from '@components/blog-posts-section/blog-posts-section';

import * as s from './index.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';

import shield_ico from '@assets/images/icons/shield.svg';
import key_ico from '@assets/images/icons/key.svg';
import money_ico from '@assets/images/icons/money.svg';

const Home = () => {

  const data = useStaticQuery(graphql`
    query {
      metaImg: file(relativePath: { eq: "images/metaImg.jpg" }) {
        publicURL
      }
      heroMobileBg: file(relativePath: { eq: "images/hero/mobile_bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      heroDesktopBg: file(relativePath: { eq: "images/hero/desktop_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);


  return (
    <MainLayout>

      <Seo
        title='Leasing i wynajem samochodów, maszyn i urządzeń'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s.hero} ${s_wrapper.apply} ${s_wrapper.apply_padding}`}>

        <h1>Co możemy dla Was <br className={s.desktop_br} /> sfinansować?</h1>
        <p className={`${s_text.subtitle} ${s.subtitle}`}>
	        Szukasz profesjonalnego wsparcia w pozyskaniu finansowania<br/>
	        na niezbędne dla Ciebie maszyny, urządzenia?<br/>
	        Planujesz zakup auta czy też wymieniasz inne środki trwałe?<br/>
	        Oferujemy przygotowanie najkorzystniejszej oferty wspierającej Twój biznes!
        </p>

        <GatsbyImage image={getImage(data.heroMobileBg)} alt='zdjęcie w tle' className={s.mobile_bg} />
        <GatsbyImage image={getImage(data.heroDesktopBg)} alt='zdjęcie w tle' className={s.desktop_bg} />

        <SearchFilters classes={s.search_styles} redirect_mode={true} dark_mode={true} />

      </section>


      <PromoSection />


      <section className={`${s.offer} ${s_wrapper.apply} ${s_wrapper.apply_low_top_height}`}>

        <h2>Oferta</h2>
        <p className={`${s_text.subtitle} ${s.subtitle}`}>
	        Od lat przeprowadzamy przygotowywane indywidualnie transakcje finansowe dla przedsiębiorców i osób prywatnych.
        </p>

        <p className={s.general_info}>
	        Niezależnie od tego czy jesteś właścicielem małej, średniej czy dużej firmy lub
	        po prostu chcesz na przykład kupić auto prywatnie to wesprzemy Cię w wyborze
	        najkorzystniejszej formy finansowania i przeprowadzimy przez cały proces,
	        zapewniając wsparcie oraz stały kontakt.
        </p>

        <div className={s.items_wrapper}>

          <div className={s.item}>

            <img src={shield_ico} alt='Tarcza' />
            <h3>Ubezpieczenia OC AC GAP</h3>
            <p>
	            Ubezpieczenie komunikacyjne (OC) jest ubezpieczeniem ustawowo obowiązkowym.
	            Uzupełnienie pakietu o dobrowolne AC, NNW, Assistance oraz GAP daje maksymalne
	            poczucie bezpieczeństwa w razie nieprzewidzianych zdarzeń losowych i pozwala
	            na komfortowe przemieszczanie się na terenie kraju i za granicą.
            </p>

          </div>

          <div className={s.item}>

            <img src={key_ico} alt='Klucz' />
            <h3>Wynajem długoterminowy</h3>
            <p>
	            Idealne rozwiązanie dla osób, które chcą cieszyć się użytkowaniem samochodu i
	            maksymalnie zmniejszyć koszty eksploatacyjne. Miesięczna rata wynajmu długoterminowego,
	            oprócz opłaty abonamentowej, może zawierać również ubezpieczenie komunikacyjne
	            oraz pakiet usług serwisowych.
            </p>

          </div>

          <div className={s.item}>

            <img src={money_ico} alt='Pieniądze' />
            <h3>Kredyty samochodowe<br/>Dla firm i osób fizycznych</h3>
            <p>
	            Form finansowania przedmiotu jest kilka, jeżeli w danym momencie popularny leasing
	            operacyjny nie będzie najlepszym rozwiązaniem to zaproponujemy kredyt celowy,
	            na przykład na zakup wymarzonego samochodu.
            </p>

          </div>

        </div>

      </section>


      <OpinionsSection />

      {/*<BlogPostsSection />*/}

      <AboutSection />

      <ContactSection />

      <MapSection />

    </MainLayout>
  );

}

export default Home;
