import React from 'react';
import {useQuery, gql, ApolloClient, InMemoryCache} from '@apollo/client';
import { Link } from 'gatsby';

import CarTile from '@components/car-tile/car-tile';
import HandleLoading from '@hoc/handle-loading/handle-loading';

import * as s from './promo-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const generalClient = new ApolloClient({
	uri: 'https://wp.clicklease.pl/graphql',
	cache: new InMemoryCache()
});

const PROMO_CAR_QUERY = gql`
    query PromoCarQuery {
        cars(first: 4) {
            nodes {
                slug
                car {
                    fuelType
                    gearboxType
                    mainImg
                    year
                    price
                    installmentFrom
                    name
                }
            }
        }
    }
`;

const PromoSection = ({ classes }) => {

	const { loading, error, data } = useQuery(PROMO_CAR_QUERY, {
		client: generalClient
	});

	return (
		<section className={`${s.promo_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

			<h2>Aktualne promocje</h2>

			<p className={`${s_text.subtitle} ${s.subtitle}`}>
				Skorzystaj z najnowszych promocji i stań się właścicielem swojego wymarzonego auta w kilku prostych krokach.
			</p>

			<div className={s.items_wrapper}>

				<HandleLoading loading={loading} error={error}>
					{data && data.cars.nodes.map((el, id) => (
						<CarTile
							key={id}
							classes={s.item}
							mainImg={el.car.mainImg}
							slug={el.slug}
							name={el.car.name}
							fuelType={el.car.fuelType}
							gearboxType={el.car.gearboxType}
							year={el.car.year}
							price={el.car.price}
							installmentFrom={el.car.installmentFrom}
						/>
					))}
				</HandleLoading>

			</div>

			<Link to='/leasing-wynajem' className={`${s_btns.btn_full} ${s_btns.btn_arrow} ${s.more_btn}`}>Więcej</Link>


		</section>
	)
};

export default PromoSection;